<template>
  <v-card>
    <v-card-title>
      Review Forests
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="forests"
      :server-items-length="totalAmount"
      class="elevation-1"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': $options.DEFAULT_ITEMS_PER_PAGE_OPTIONS,
      }"
      fixed-header
      :options.sync="options"
    >
      <template v-slot:[`item.name`]="{ item }">
        <router-link :to="{ name: 'Forest', params: { id: item._id } }">{{
          item.name
        }}</router-link>
      </template>
      <template v-slot:[`item.slug`]="{ item }">
        <a :href="`${$options.FOREST_DOMAIN}/${item.slug}`" target="_blank">{{
          `${$options.FOREST_DOMAIN}/${item.slug}`
        }}</a>
      </template>
      <template v-slot:[`item.platforms`]="{ item }">
        <platforms-logo :platforms="item.platformsOrder"></platforms-logo>
      </template>
      <template v-slot:[`item.projectId`]="{ item }">
        {{ $options.PLANTING_PROJECTS[item.projectId] }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        {{ item.active ? "Active" : "Deactivated" }}
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ formatDate(item.createdAt) }}
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { API } from "../../api";
import {
  PLATFORM_TYPES,
  PLANTING_PROJECTS,
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_ITEMS_PER_PAGE_OPTIONS,
  FOREST_TYPES,
} from "../../constants";
import { formatDate } from "../../helpers";

import PlatformsLogo from "../Base/PlatformsLogo.vue";

export default {
  name: "CompanyForests",
  PLATFORM_TYPES,
  PLANTING_PROJECTS,
  DEFAULT_ITEMS_PER_PAGE_OPTIONS,
  FOREST_TYPES,
  FOREST_DOMAIN: process.env.VUE_APP_FOREST_DOMAIN,
  components: {
    PlatformsLogo,
  },
  props: {
    companyId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      formatDate,
      loading: true,
      forests: [],
      search: "",
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Link",
          sortable: true,
          value: "slug",
        },
        {
          text: "Type",
          sortable: true,
          value: "type",
        },
        {
          text: "Platforms",
          sortable: false,
          value: "platforms",
        },
        {
          text: "Trees Planted",
          sortable: true,
          value: "totalTreeAmount",
        },
        {
          text: "Review amount",
          sortable: true,
          value: "reviewAmount",
        },
        {
          text: "Review amount on platform",
          sortable: true,
          value: "reviewAmountOnPlatform",
        },
        {
          text: "Planting Project",
          sortable: true,
          value: "projectId",
        },
        {
          text: "Status",
          sortable: true,
          value: "status",
        },
        {
          text: "Created at",
          sortable: true,
          value: "createdAt",
        },
      ],
      options: {
        page: 1,
        itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
        sortBy: ["createdAt"],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false,
      },
      totalAmount: 0,
    };
  },
  computed: {
    searchQuery() {
      const trimmedSearch = this.search.trim();
      return trimmedSearch === "" ? null : trimmedSearch;
    },
  },
  watch: {
    companyId: {
      handler: "getForests",
    },
    search: {
      handler: "debounceFetching",
    },
    options: {
      handler: "debounceFetching",
      deep: true,
    },
  },
  methods: {
    debounceFetching() {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }

      this.timerId = setTimeout(() => {
        this.getForests();
      }, 500);
    },
    async getForests() {
      if (!this.companyId) {
        this.loading = false;
        return;
      }

      if (this.companyId) {
        this.forests = [];
      }

      this.loading = true;
      try {
        const forests = await API.getForests({
          companyId: this.companyId,
          sortBy: this.options.sortBy[0],
          order: this.options.sortDesc[0] ? "desc" : "asc",
          page: this.options.page,
          pageSize:
            this.options.itemsPerPage === -1 ? 0 : this.options.itemsPerPage,
          query: this.searchQuery,
        });
        this.forests = forests.data;
        this.totalAmount = forests.count;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
