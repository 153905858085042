import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v("List of Trees by Forest Platform")]),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.treeReports,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalAmount,"footer-props":{
      'items-per-page-options': _vm.$options.DEFAULT_ITEMS_PER_PAGE_OPTIONS,
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.forest",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Forest', params: { id: item.forest.id } }}},[_vm._v(" "+_vm._s(item.forest.name)+" ")])]}},{key:"item.ownedBy",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Company', params: { id: item.ownedBy.id } }}},[_vm._v(" "+_vm._s(item.ownedBy.name)+" ")])]}},{key:"item.platformType",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.platformType)+" ")]}},{key:"item.projectId",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.PLANTING_PROJECTS[item.projectId])+" ")]}},{key:"item.countingTimestamp",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.countingTimestamp * 1000))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }